<template>
  <main-card-header :title="routerTitle"></main-card-header>
  <div class="flex justify-center items-center content-evenly flex-wrap">
    <small-form
      title="Add User"
      instructions="Enter the new user name"
      :inputs="formItems"
      @resolve-form-submit="addUser"
      :errorMessage="newUserError"
    ></small-form>
    <div>
      <div class="flex justify-center flex-wrap">
        <div class="flex justify-center">
          <card-widget
            cardTitle="Users"
            icon="person"
            :cardValue="getUsers.total"
          ></card-widget>
          <card-widget></card-widget>
        </div>
      </div>
      <div class="flex justify-center flex-wrap">
        <div class="flex justify-center">
          <card-widget></card-widget>
          <card-widget></card-widget>
        </div>
      </div>
    </div>
  </div>
  <template v-if="!isLoading">
    <dynamic-table title="Users" @delete="deleteUser"></dynamic-table>
  </template>
  <template v-else>
    <heart-rate fast="true"></heart-rate>
  </template>

  <my-pagination
    v-if="!!getUsers.total"
    :options="options"
    v-model="page"
    :records="getUsers.total"
    :per-page="getUsers.per_page"
    @paginate="myCallback"
  >
  </my-pagination>
  <blank-paginate v-else />
</template>
<script>
import { markRaw } from "vue";
import customPaginate from "@/components/ui/baseComponents/paginate.vue";
import blankPaginate from "@/components/ui/baseComponents/blankPaginate.vue";
import dynamicTable from "@/components/layout/SA/table/table.vue";
import smallForm from "@/components/ui/forms/smallForm.vue";
import cardWidget from "@/components/ui/SA/cardWidget.vue";
export default {
  components: { dynamicTable, smallForm, cardWidget, blankPaginate },
  data() {
    return {
      options: {
        template: markRaw(customPaginate),
      },
      page: 1,
      tableHeaders: ["NAME", "EMAIL", "ROLES", "PRACTICES", "ACTIONS"],
      formItems: [
        {
          type: "input",
          placeholder: "Email",
          fieldName: "email",
        },
        {
          type: "input",
          placeholder: "Name",
          fieldName: "name",
        },
        {
          type: "input",
          placeholder: "Password",
          fieldName: "password",
        },
        {
          type: "input",
          placeholder: "Password Confirmation",
          fieldName: "password_confirmation",
        },
      ],
      isLoading: false,
      newUserError: null,
    };
  },
  provide() {
    return { headers: this.tableHeaders };
  },
  created() {
    this.loadUsers(this.page);
  },
  methods: {
    myCallback(e) {
      this.loadUsers(e);
    },
    // Load Users
    async loadUsers(page) {
      this.isLoading = true;
      try {
        await this.$store.dispatch("admin/loadUsers", page);
        this.isLoading = false;
      } catch (error) {
        this.error = error.message || "Something went wrong!";
      }
    },
    // Delete User
    async deleteUser(id) {
      try {
        await this.$store.dispatch("admin/deleteUser", id);
        this.isLoading = false;
        this.$toast.error(`User Deleted!`);
      } catch (error) {
        this.error = error.message || "Something went wrong!";
      }
    },
    // Add User
    async addUser(data) {
      try {
        await this.$store.dispatch("admin/addUser", data);
        this.newUserError = null;
        this.isLoading = false;
        this.$toast.success(`Useer Added!`);
      } catch (error) {
        this.error = error.message;
        this.newUserError = error.message;
      }
    },
  },
  computed: {
    routerTitle() {
      return this.$route.meta.title;
    },
    getPractices() {
      return this.$store.getters["admin/getPractices"];
    },

    getUsers() {
      return this.$store.getters["admin/getUsers"];
    },
  },
};
</script>
